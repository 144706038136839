@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}