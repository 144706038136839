/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: darkorange;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of other elements */
  }
  
  .loader {
    width: 100px;
    height: 100px;
    background-color: white;
    animation: flip 0.8s infinite; /* Slightly slower flipping animation speed */
  }
  
  @keyframes flip {
    0% {
      transform: perspective(400px) rotateY(0deg);
    }
    50% {
      transform: perspective(400px) rotateY(180deg);
    }
    100% {
      transform: perspective(400px) rotateY(360deg);
    }
  }
  